<template>
  <b-overlay :show="isLoading" rounded="lg" opacity="0.6" spinner-variant="primary">
    <template #overlay>
      <div class="d-flex align-items-center">
        <b-spinner small type="grow" variant="secondary"></b-spinner>
        <b-spinner type="grow" variant="dark"></b-spinner>
        <b-spinner small type="grow" variant="secondary"></b-spinner>
      </div>
    </template>
    <b-row>
      <b-col class="mt-4">
        <i class="fa fa-backward"></i>
        <router-link :to="{ name: MagazinePath.name }">
          Magazines
        </router-link>
      </b-col>
    </b-row>
    <b-row v-if="!item.id && !isLoading">
      <h3 class="col text-center">Item Not Found</h3>
    </b-row>
    <b-row>
      <b-col lg="12">
        <b-form @submit="onSubmit" v-if="show" id="form-edit">
          <b-card :header="item.name + ' #' + item.id" header-bg-variant="primary" header-tag="h5" header-class="font-weight-bold">
            <b-row>
              <b-col lg="4" md="6">
                <b-form-group id="input-display" label="Display Name (Item’s Title)" label-for="display">
                  <b-form-input id="display" v-model="form.name" type="text" placeholder="Enter Display Name" required>
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col lg="4" md="6">
                <b-form-group id="input-code" label="Edition Code" label-for="code">
                  <b-form-input id="code" v-model="form.edition_code" type="text" placeholder="Enter Edition Code"
                    required></b-form-input>
                </b-form-group>
              </b-col>
              <b-col lg="4" md="6">
                <b-form-group id="input-slug" label="Slug" label-for="slug">
                  <b-form-input id="slug" v-model="form.slug" type="text" required></b-form-input>
                </b-form-group>
              </b-col>
              <b-col lg="4" md="6">
                <b-form-group label="Item Status">
                  <b-form-select id="currency" v-model="form.item_status" :options="itemStatusOptions" required>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col lg="4" md="6">
                <b-form-group id="input-issue" label="Issue Number" label-for="issue">
                  <b-form-input :disabled="userData.role_id === 5"  id="issue" v-model="form.issue_number" type="text" placeholder="Enter Issue Number"
                    required></b-form-input>
                </b-form-group>
              </b-col>
              <b-col></b-col>
              <b-col lg="4" md="6">
                <label>Languages</label>
                <multiselect placeholder="Enter languages" open-direction="bottom" v-model="form.languages" label="name"
                  track-by="name" :custom-label="nameWithISO" :options="languagesSearchOptions" :searchable="true"
                  :close-on-select="true" :options-limit="10" :multiple="true" required>
                </multiselect>
              </b-col>
              <b-col lg="4" md="6">
                <b-form-group label="Countries">
                  <multiselect placeholder="Enter Countries" open-direction="bottom" v-model="form.countries"
                    label="name" track-by="name" :custom-label="nameWithISO" :options="countriesSearchOptions"
                    :loading="isCountriesSearch" :searchable="true" :close-on-select="true" :options-limit="10"
                    :multiple="true">
                  </multiselect>
                </b-form-group>
              </b-col>
              <b-col lg="4" md="6">
                <b-form-group label="Categories">
                  <multiselect v-model="form.categories" label="name" track-by="name" placeholder="Enter Categories"
                    open-direction="bottom" :options="categoriesSearchOptions" :loading="isCategoriesSearch"
                    :searchable="true" :close-on-select="true" :options-limit="10" :multiple="true"
                    @search-change="categoriesFind">
                  </multiselect>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
          <b-card header="Upload Asset" header-bg-variant="primary" header-tag="h5" header-class="font-weight-bold">
            <b-row align-v="center">
              <b-col>
                <b-form-file
                  v-model="form.file_content"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                  accept=".pdf, .epub"
                >
                </b-form-file>
              </b-col>
              <b-col>
                <b-button
                  pill
                  size="sm"
                  variant="primary"
                  @click="onUpload"
                  v-if="form.file_content"
                ><i class="fa fa-upload"></i> Upload</b-button>
              </b-col>
              <b-col lg="1"></b-col>
            </b-row>
            <div>Max Size 150Mb</div> 
            <b-row>
              <b-col>
                File Size: {{ form.file_content ? form.file_content.size : 0 | convertSize }} MB
              </b-col>
              <b-col>
                Current File Size: (
                {{ form.current_size ? form.current_size : 0 | convertSize }} MB 
                )
              </b-col>
            </b-row>
          </b-card>
          <b-card header="Detail" header-bg-variant="primary" header-tag="h5" header-class="font-weight-bold">
            <b-row>
              <b-col md="6">
                <DatetimeCustom
                  :label="'Release Date (GMT ' + timezoneString() + ')'"
                  v-model="form.release_date"
                ></DatetimeCustom>
              </b-col>
              <b-col md="6">
                <DatetimeCustom
                  :label="'Release Schedule (GMT ' + timezoneString() + ')'"
                  v-model="form.schedule_date"
                ></DatetimeCustom>
              </b-col>
              <b-col md="6">
                <b-form-group id="input-currency" label="Printed Price Currency" label-for="currency">
                  <b-form-select id="currency" v-model="form.currency" :options="currencys" required></b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group id="input-price" label="Printed Price" label-for="price">
                  <b-form-input id="price" v-model="form.price" type="number" step="any" placeholder="1.200.000"
                    required></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group id="input-content" label="Content Type" label-for="content">
                  <b-form-select id="content" v-model="form.content" :options="contents" required></b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group id="input-parental" label="Parental Control" label-for="parental">
                  <b-form-select id="parental" v-model="form.parental" :options="parentals" required></b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group id="input-distribution" label="Distribution Countries" label-for="distribution">
                  <b-form-select id="distribution" v-model="form.distribution" :options="distributionSearchOptions" disabled>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group id="input-reading" label="Reading Direction" label-for="reading">
                  <b-form-select id="reading" v-model="form.reading" :options="readings" required></b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="6" v-if="!isProfileVendor">
                <label>Category Ax</label>
                <multiselect v-model="form.category_ax" label="name" track-by="name" placeholder="Category Ax"
                  open-direction="bottom" :options="axSearchOptions" :searchable="true" :loading="isAxSearch"
                  :close-on-select="true" :options-limit="10" :multiple="false" @search-change="axFind" disabled>
                </multiselect>
              </b-col>
              <b-col md="12">
                <div class="pt-2">
                  <b-form-checkbox v-model="form.is_active" value="true" unchecked-value="false"> Active
                  </b-form-checkbox>
                  <b-form-checkbox v-model="form.is_internal_content" value="true" unchecked-value="false"
                    v-if="!isProfileVendor"
                  > Internal Content
                  </b-form-checkbox>
                </div>
              </b-col>
            </b-row>
          </b-card>
          <b-card header="Optional" header-bg-variant="primary" header-tag="h5" header-class="font-weight-bold">
            <b-row>
              <b-col md="12">
                <b-form-group id="input-description" label="Description" label-for="description">
                  <b-form-textarea id="description" v-model="form.description" placeholder="Enter something..." rows="6"></b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </b-form>
      </b-col>
    </b-row>
    <b-button class="fix-bot-right" size="lg" variant="primary" type="submit" form="form-edit" :hidden="isLoading">
      <i class="fa fa-save"> Save</i>
    </b-button>
  </b-overlay>
</template>

<script>
import moment from "moment";
import { mapState, mapActions } from "vuex";
import { MagazinePath } from "../../../router/content";
import constant from "../../../store/constant";
import DatetimeCustom from "../../../components/form/Datetime.vue"

const { ITEM_TYPES } = constant;
delete constant.CONTENT_TYPE.AUDIO;
const CONTENT_TYPE = Object.values(constant.CONTENT_TYPE);
const READING_DIRECTION = Object.entries(constant.READING_DIRECTION).map((item) => {
  return {
    text: item[0],
    value: item[1],
  }
});
const PARENTAL_CONTROL = Object.entries(constant.PARENTAL_LEVEL_TYPE).map((item) => {
  return {
    text: item[0],
    value: item[1],
  }
});
const PRINTED_PRICE = Object.values(constant.PRINTED_PRICE_ITEM);
const ITEM_STATUS = Object.entries(constant.ITEM_STATUS).map(item => { return { text: item[0], value: item[1] } })

export default {
  name: "editMagazine",
  components: {
    DatetimeCustom
  },
  data() {
    return {
      MagazinePath,
      userData : JSON.parse(localStorage.getItem('session_auth')),
      basePathCover: constant.URL_STATIC.EBOOK_COVER,
      form: {
        name: "",
        issue_number: "",
        edition_code: "",
        slug: "",
        release_date: "",
        schedule_date: "",
        price: "",
        description: "",
        category_ax: [],
        languages: [],
        countries: [],
        categories: [],
        item_status: [],
        currency: null,
        content: null,
        parental: null,
        distribution: null,
        reading: null,
        file_content: null,
        is_active: false,
        is_internal_content: false,
      },
      currencys: [
        { text: "Select Currency", value: null }, ...PRINTED_PRICE],
      contents: [{ text: "Select Content", value: null }, ...CONTENT_TYPE],
      parentals: [{ text: "Select Parental Type", value: null }, ...PARENTAL_CONTROL],
      readings: [
        { text: "Select Direction", value: null }, ...READING_DIRECTION
      ],
      distributionSearchOptions: [
        { text: "Select Distributin Countries", value: null }
      ],
      itemStatusOptions: ITEM_STATUS,
      show: true,
      item_id: null,
      axSearchOptions: [],
      categoriesSearchOptions: [],
      countriesSearchOptions: [],
      languagesSearchOptions: [],
      isAxSearch: false,
      isCategoriesSearch: false,
      isCountriesSearch: false,
      debounce: null,
    };
  },
  computed: {
    ...mapState({
      isError: (state) => state.magazine.isError,
      isLoading: (state) => state.magazine.isLoading,
      errorMessage: (state) => state.magazine.errorMessage,
      successMessage: (state) => state.magazine.successMessage,
      item: (state) => state.magazine.item,
      refresh: (state) => state.magazine.refresh,
      isProfileVendor: (state) => state.profile?.vendor_id,
    }),
  },
  watch: {
    errorMessage: function () {
      if (!this.errorMessage) return;
      this.messageAlert('error', this.errorMessage, 5000);
    },
    successMessage: function () {
      if (!this.successMessage) return;
      this.messageAlert('success', this.successMessage);
      // this.fetchMagazineById({ id });
    },
    item: function () {
      if (!Object.keys(this.item).length) return;
      this.setEditMagazine();
    },
    refresh: function () {
      if (!this.refresh) return;
      this.fetchMagazineById({ id: this.item_id });
    }
  },
  created() {
    this.setCountriesOptions();
    this.languagesSearchOptions = Object.entries(constant.LANGUAGE_CHOICES).map((item) => {
      return {
        iso: item[0].toUpperCase(),
        name: item[1]
      }
    });
    let id = this.$route.params.id;
    this.item_id = id;
    this.fetchMagazineById({ id });
    this.fetchProfile();
  },
  methods: {
    ...mapActions('profile', ['fetchProfile']),
    ...mapActions('magazine', ['updateMagazine', 'uploadAsset', 'fetchMagazineById']),
    ...mapActions('items', ['searchCategoriesV2', 'getCountries']),
    ...mapActions('ax', ['searchCategoryAx']),
    onSubmit(event) {
      event.preventDefault();
      if (!this.validateForm()) return;

      const data = this.form;
      const payload = {
        item_id: this.item_id,
        name: data.name,
        edition_code: data.edition_code,
        slug: data.slug,
        content_type: data.content.toLowerCase(),
        printed_price: data.price,
        categories_id: data.categories.map(item => item.id),
        release_date: data.release_date,
        release_schedule: data.schedule_date,
        description: data.description,
        printed_currency_code: data.currency,
        issue_number: data.issue_number,
        is_active: data.is_active,
        is_internal_content: data.is_internal_content,
        category_ax_id: data.category_ax.id,
        parentalcontrol_id: data.parental,
        reading_direction: data.reading,
        item_distribution_country_group_id: data.distribution,
        countries: data.countries.map(item => item.iso.toLowerCase()),
        languages: data.languages.map(item => item.iso.toLowerCase()),
        item_status: this.form.item_status,
      }
      this.updateMagazine(payload);
    },

    validateForm() {
      this.form.edition_code = this.form?.edition_code.trim();
      this.form.slug = this.form?.slug.trim();

      if (this.form?.edition_code?.includes(' ')) {
        this.$swal('Edition Code!!', 'cannot contain spaces', 'warning');
        return false;
      }
      if (this.form?.slug?.includes(' ')) {
        this.$swal('Slug!!', 'cannot contain spaces', 'warning');
        return false;
      }

      return true;
    },

    onUpload() {
      this.isDownload = true;
      const payload = {
        id: this.item_id,
        file_content: this.form.file_content,
        item_status: this.form.item_status,
        release_schedule: this.form.schedule_date,
      };
      if (!payload.file_content) {
        this.messageAlert('error', 'No File Selected !', 5000);
        return;
      }
      this.uploadAsset(payload);
    },

    axFind(query) {
      if (!query) return;

      this.isAxSearch = true;
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        let payload = {
          q: query,
          limit: 10,
        };
        this.searchCategoryAx(payload).then((response) => {
          this.axSearchOptions = response.data.data.rows;
          this.isAxSearch = false;
        }).catch(() => {
          this.isAxSearch = false;
        });
      }, 600);
    },

    categoriesFind(query) {
      if (!query) return;

      this.isCategoriesSearch = true;
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.searchCategoriesV2({
          name: query,
          item_type: ITEM_TYPES.MAGAZINE.toLowerCase(),
          is_active: 1,
        })
          .then((response) => {
            this.categoriesSearchOptions = response.data.data.rows;
            this.isCategoriesSearch = false;
          })
          .catch(() => {
            this.isCategoriesSearch = false;
          });
      }, 600);
    },

    setCountriesOptions(setCountries = []) {
      this.isCountriesSearch = true;
      this.getCountries()
        .then((response) => {
          this.countriesSearchOptions = response.data.data.rows;
          this.isCountriesSearch = false;

          this.form.countries = this.countriesSearchOptions
            .filter(
              item => [...setCountries].includes(item.iso.toLowerCase())
            );
        })
        .catch(() => {
          this.isCountriesSearch = false;
        });
    },

    messageAlert(icon, title, timer = 3000) {
      this.$swal({
        toast: "true",
        position: "top-end",
        icon,
        title,
        showConfirmButton: false,
        timer,
        timerProgressBar: true,
      });
    },

    nameWithISO({ name, iso }) {
      return `${iso} - ${name}`
    },

    setEditMagazine() {
      const data = this.item;
      this.form.name = data.name;
      this.form.issue_number = data.issue_number;
      this.form.edition_code = data.edition_code;
      this.form.slug = data.slug;
      this.form.release_date = moment(data.release_date).toString();
      this.form.schedule_date = moment(data.release_schedule).toString();
      this.form.price = data.printed_price;
      this.form.currency = data.printed_currency_code;
      this.form.content = data.content_type.toUpperCase();
      this.form.parental = data.parentalcontrol_id;
      this.form.reading = data.reading_direction;
      this.form.is_internal_content = data.is_internal_content;
      this.form.is_active = data.is_active;
      this.form.current_size = data.file_size;
      this.form.file_content = null;
      this.form.item_status = data.item_status;

      this.form.languages = this.languagesSearchOptions
        .filter(
          item => [...data.languages].includes(item.iso.toLowerCase())
        );
      this.setCountriesOptions(data.countries);

      this.form.category_ax = {
        id: data.category_ax.category_ax_id,
        name: data.category_ax.category_ax,
      }
      this.form.categories = data.categories;
      this.form.description = data.description;

    },
  },
  filters: {
    convertSize(value) {
      const size = parseFloat(value);
      return (size / 1000 ** 2).toFixed(1)
    }
  }
};
</script>

<style lang="css" scoped>
.fix-bot-right {
  position:fixed;
  bottom: 20px;
  right: 20px;
  box-shadow: 0 0 10px white;
}
</style>
